@import "~normalize.css";
@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

$color-red: #ff5647;

$color-bg: #161616;
$color-text-main: #fff;
$color-text-secondary: #bbb;
$color-text-third: #777;

body {
  background: $color-bg;
  font-family: "Open Sans", sans-serif;
  color: $color-text-main;
}

.app {
  max-width: 800px;
  padding: 20px;
  margin: auto;
}

p {
  color: $color-text-secondary;

  & > strong {
    color: $color-text-main;
    font-weight: normal;
  }
}

.header {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 610px) {
    flex-direction: row;
  }

  @media screen and (min-width: 900px) {
    margin-top: 50px;
  }

  align-items: center;

  &Image {
    min-width: 150px;
    min-height: 150px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 20px;

    border: 2px solid $color-text-main;

    @media screen and (min-width: 610px) {
      margin-bottom: unset;
    }
  }

  &Info {
    @media screen and (min-width: 610px) {
      margin-left: 20px;
    }

    @media screen and (min-width: 900px) {
      margin-left: 50px;
    }

    // h1,
    // h2,
    // h3 {
    //   margin: 0;
    // }

    &Name {
      margin: 0;
      margin-bottom: 10px;
    }

    &Date {
      margin: 0;
      margin-bottom: 10px;
      color: $color-text-third;
    }

    &Text {
      color: $color-text-secondary;
    }
  }
}

.intro {
  margin-top: 50px;
  @media screen and (min-width: 900px) {
    margin-top: 100px;
  }
}

.fundraiser {
  @media screen and (min-width: 900px) {
    margin-top: 100px;
  }

  .widget {
    & > div {
      margin-top: 20px;

      @media screen and (min-width: 900px) {
        margin-top: 50px;

        display: flex;
        justify-content: center;
      }

      & > div {
        flex: 1;
        height: unset;

        & > :nth-child(2) {
          margin-top: 0 !important;
          display: flex;

          @media screen and (min-width: 900px) {
            justify-content: center;
          }

          & > :first-child {
            margin-left: 0;
            color: $color-text-main !important;
          }

          margin-bottom: 20px;
        }

        & > :nth-child(5) {
          // margin-top: 0 !important;
          // display: flex;
          // justify-content: center;
          display: flex;

          @media screen and (min-width: 900px) {
            justify-content: center;
          }

          & > button {
            @media screen and (max-width: 500px) {
              width: 100%;
            }

            display: block;
            padding: 0;

            color: #fff;
            background: $color-text-secondary;

            border: none;
            border-radius: 3px !important;
            margin: 0 !important;

            cursor: pointer;

            transition: all;
            transition-duration: 0.2s;

            box-sizing: content-box;
          }
        }

        & > :first-child {
          display: none;
        }
      }
    }
  }
}

.info {
  @media screen and (min-width: 900px) {
    margin-top: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 100px;
  }

  &Block {
    flex: 1;
  }
}

.register {
  @media screen and (min-width: 900px) {
    margin-top: 100px;
  }

  &Loader {
    display: flex;
    justify-content: center;
    & > svg {
      width: 100px;
      height: 100px;
      animation: spin 1s linear infinite;
      // position: fixed;
    }

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &Title {
    &Counter {
      color: $color-text-third;
    }
  }

  &Messages {
    &Message {
      margin-bottom: 50px;
      &Title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        span {
          color: $color-text-third;
          margin-left: 5px;
        }

        h4 {
          margin: 0;
        }
      }

      p {
        color: $color-text-secondary;

        white-space: pre-line;

        a {
          color: $color-text-main;
        }
      }

      &Images {
        display: flex;
        flex-wrap: wrap;
        &Image {
          margin-right: 5px;

          position: relative;

          img {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border: 2px solid $color-text-third;
            border-radius: 3px;

            &:hover {
              cursor: pointer;
            }
          }

          &Loader {
            width: 100px;
            height: 100px;
            top: 0;
            position: absolute;
            border: 2px solid $color-text-third;
            border-radius: 3px;

            & > svg {
              width: 100px;
              height: 100px;
              animation: spin 1s linear infinite;
              // position: fixed;
            }

            @keyframes spin {
              100% {
                transform: rotate(360deg);
              }
            }
          }
        }
      }
    }
  }
}

.button {
  display: block;
  padding: 0;

  padding: 10px 15px 10px 15px;

  color: #fff;
  background: $color-text-secondary;

  border: none;
  border-radius: 3px;

  cursor: pointer;

  transition: all;
  transition-duration: 0.2s;

  box-sizing: content-box;

  &:disabled {
    opacity: 0.9;
    cursor: default;
    // cursor: wait;
  }

  &:focus {
    outline-width: 0;
  }

  &:hover {
    opacity: 0.9;
  }
}

.input {
  // margin-top: 10px;
  margin-top: 10px;

  padding: 12px 20px;
  background: hsla(0, 0%, 53.3%, 0.2);
  border: none;
  border-radius: 3px;
  // padding: 0;
  color: $color-text-main;

  &:focus {
    outline-width: 0;
  }

  &Error {
    border: 1px solid $color-red;

    &Label {
      color: $color-red !important;
    }
  }
}

.textArea {
  resize: vertical;
}

.formButton {
  margin-top: 10px;
}

.dropzone {
  margin-top: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 3px;
  border: 2px dashed $color-text-third;

  color: $color-text-secondary;
  outline: none;

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &Dragging {
    border: 2px dashed $color-text-main;
  }

  &Button {
    // position: absolute;
    flex: 1;
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    background: none;
    border: none;
    outline: none;
    color: $color-text-secondary;

    &:hover {
      cursor: pointer;
    }

    &Dragging {
      color: $color-text-main;
    }

    // span {
    //   height: 50px;
    // }
  }

  &Error {
    border: 2px dashed $color-red;

    &Span {
      margin-bottom: 5px;
      color: $color-red !important;
    }
  }
  // transition: border 0.24s ease-in-out;
}

.preview {
  margin-top: 10px;
  // &Images {
  display: flex;
  flex-wrap: wrap;
  &Image {
    position: relative;
    margin-right: 5px;

    img {
      width: 100px;
      height: 100px;
      object-fit: cover;

      border: 2px solid $color-text-third;
      border-radius: 3px;
    }

    &Close {
      margin: 5px;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 50%;
      background: $color-bg;
      color: $color-text-main;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  // }
}

.icon {
  fill: $color-text-main;
  width: 10px;
  height: 10px;
}

.imagePopup {
  display: flex;
  justify-content: center;

  & > img {
    max-height: 100%;
    max-width: 100%;
  }
}

.messagePopup {
  &Info {
    color: $color-text-secondary;
  }
}

div[id^="myModalrememberkastiop"] {
  color: #000;
}
