// @import "../../core.scss";

.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  margin: 0;
  padding: 20px;
  display: flex;
  // flex-direction: row;
  justify-content: center;

  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  // animation: newsletterPopupContainerEnter 0.5s ease;

  &Title {
    margin-top: 0;
    margin-bottom: 20px;
  }

  &Closer {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    &Text {
      color: #aaaaaa;
      cursor: pointer;
    }
  }

  &Container {
    overflow: auto;
    color: #fff;
    display: block;
    width: 100%;
    max-width: 580px;
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    background-color: #161616;

    color: white;
    border-radius: 5px;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.35);
    animation: newsletterPopupEnter 0.5s ease;

    &Content {
      padding: 20px;
      overflow: auto;

      & > form {
        display: flex;
        flex-direction: column;
        & > span {
          color: #aaaaaa;
          cursor: pointer;
          display: block;
          margin-top: 10px;
          // text-align: center;
        }
      }
    }
  }
}

@keyframes newsletterPopupEnter {
  0% {
    transform: translateY(-100vh);
  }
  100% {
    transform: translateY(0);
  }
}
