.button {
  height: 1.5em;
  &Loader {
    height: 1.5em;
    // position: fixed;
    // width: 100%;
    // height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
      width: 50px;
      height: 50px;
      animation: spin 1s linear infinite;
      // position: fixed;
    }

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
